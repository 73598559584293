import React, { useState } from 'react'
import { useSpring, animated } from 'react-spring'
import { navigate } from 'gatsby'

import * as S from '../styles/login.styles'
import * as I from '../components/shared/Icons'
import Layout from '../templates/Layout'
import { DARK_BLUE, PRIMARY } from '../constants/colors'
import { fetchLogin } from '../services/fetch/auth'
import * as session from '../services/session'
import { defaultErrorMsg } from '../components/forms/util/helpers'
import { ErrorMessage, UnusuallyLongRequestMessage } from '../components/shared'
import {
  FOROGT_PASSWORD_ROUTE,
  HOME_ROUTE,
  WORKOUTS_ROUTE,
} from '../constants/routes'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withErrorHandler } from '../components/errorHandler'

const Login = () => {
  session.redirectIfLoggedIn(WORKOUTS_ROUTE)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [pending, setPending] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const { color, opacity } = useSpring({
    color: email === '' || password === '' ? 'white' : '#000000',
    opacity: email === '' || password === '' ? 0.2 : 1,
  })

  const { color: textColor } = useSpring({
    color: email === '' || password === '' ? '#000000' : 'white',
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setPending(true)
    setError(undefined)

    const emailLowercase = email.toLowerCase()

    fetchLogin({ email: emailLowercase, password })
      .then(({ access_token: accessToken, refresh_token: refreshToken }) => {
        try {
          session.login(accessToken, refreshToken)
        } catch (err) {
          setPending(false)
          setError((err && err.message) || 'There was an issue logging you in.')
        }
      })
      .catch(err => {
        setPending(false)
        setError((err && err.message) || defaultErrorMsg)
      })
  }

  return (
    <Layout>
      <S.Container>
        <S.HeaderIconContainer>
          <I.User color={PRIMARY} strokeWidth={1.5} />
        </S.HeaderIconContainer>
        <ErrorMessage message={error} style={{ width: 350 }} />
        <UnusuallyLongRequestMessage pending={pending} />
        <S.FormWrap onSubmit={handleSubmit}>
          <S.FormInputDiv>
            <S.FormInput
              type="email"
              name="email"
              placeholder="Email"
              data-test="email"
              required
              onChange={e => setEmail(e.target.value)}
            />
          </S.FormInputDiv>
          <S.FormInputDiv>
            <S.FormInput
              type="password"
              name="password"
              placeholder="Password"
              data-test="password"
              required
              onChange={e => setPassword(e.target.value)}
            />
          </S.FormInputDiv>

          <S.SubmitButton
            as={animated.button}
            style={{
              backgroundColor: color,
            }}
            type="submit"
            data-test="submit"
            disabled={email === '' || password === ''}
          >
            <S.SubmitText
              as={animated.div}
              style={{ color: textColor, opacity }}
            >
              {pending ? 'Logging in' : 'Login'}
            </S.SubmitText>
            {pending && (
              <S.SpinnerWrap>
                <CircularProgress size={18} color="secondary" />
              </S.SpinnerWrap>
            )}
          </S.SubmitButton>
        </S.FormWrap>
        <S.SecondaryText>
          Don&apos;t have an account?{'  '}
          <span
            onClick={() => navigate(HOME_ROUTE)}
            style={{ color: DARK_BLUE, cursor: 'pointer' }}
          >
            {' '}
            Sign up today!
          </span>
        </S.SecondaryText>
        <S.SecondaryText
          onClick={() => navigate(FOROGT_PASSWORD_ROUTE)}
          style={{ paddingTop: 4, color: DARK_BLUE, cursor: 'pointer' }}
        >
          Forgot password?
        </S.SecondaryText>
      </S.Container>
    </Layout>
  )
}

export default withErrorHandler(Login)
